import {Apollo,APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {ApplicationConfig} from '@angular/core';
import {ApolloClientOptions,ApolloLink,InMemoryCache} from '@apollo/client/core';
import {HttpClient} from '@angular/common/http';
import {ErrorResponse,onError} from '@apollo/client/link/error';
import {GraphQLError} from 'graphql';
import {environment} from '../environments/environment';
import type {NormalizedCacheObject} from '@apollo/client/cache/inmemory/types';

export function apolloOptionsFactory(httpLink:HttpLink):ApolloClientOptions<NormalizedCacheObject>{
	const link:ApolloLink=onError((error:ErrorResponse):void=>{
		if(error.graphQLErrors){
			error.graphQLErrors.forEach((error:GraphQLError):void=>console.error(`[GraphQL error]:`,error));
		}
		if(error.networkError) console.error('[Network error]:',error.networkError);
	});
	return {
		// link:link.concat(httpLink.create({uri:`${environment.apiServer.url}/graphql`,withCredentials:true})),
		link:httpLink.create({uri:`${environment.apiServer.url}/graphql`,withCredentials:true}),
		cache:new InMemoryCache(),
		defaultOptions:{
			watchQuery:{
				fetchPolicy:'no-cache',
				errorPolicy:'all'
			},
			query:{
				fetchPolicy:'no-cache',
				errorPolicy:'all'
			},
			mutate:{
				fetchPolicy:'no-cache',
				errorPolicy:'none'
			}
		}
	};
}

export const graphqlProvider:ApplicationConfig['providers']=[
	Apollo,
	{
		provide:APOLLO_OPTIONS,
		useFactory:apolloOptionsFactory,
		deps:[HttpLink,HttpClient]
	}
];
