import {ApplicationConfig,importProvidersFrom,isDevMode,Provider} from '@angular/core';
import {provideRouter,withPreloading,withRouterConfig} from '@angular/router';
import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {provideServiceWorker} from '@angular/service-worker';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideHttpClient,withFetch,withInterceptors,withJsonpSupport} from '@angular/common/http';
import {graphqlProvider} from './graphql.provider';
import {AppService} from './app.service';
import {CookieService} from 'ngx-cookie-service';
import {AuthenticationService} from './authentication/authentication.service';
import {authenticationInterceptor} from './authentication/authentication.interceptor';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {SelectivePreloadingStrategyService} from './common/selective-preloading-strategy.service';
import {MAT_DATE_FORMATS,MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';

const appDefaultOptions:Provider[]=[
	{provide:MAT_DATE_LOCALE,useValue:'es-CO'},
	{
		provide:MAT_DATE_FORMATS,
		useValue:{
			parse:{
				dateInput:['l','LL']
			},
			display:{
				dateInput:'L',
				monthYearLabel:'MMM YYYY',
				dateA11yLabel:'LL',
				monthYearA11yLabel:'MMMM YYYY'
			}
		}
	},
	{
		provide:MAT_DIALOG_DEFAULT_OPTIONS,
		useValue:{
			disableClose:true,
			hasBackdrop:true,
			autoFocus:'first-tabbable'
		}
	},
	{
		provide:MAT_SNACK_BAR_DEFAULT_OPTIONS,
		useValue:{
			duration:3000,
			horizontalPosition:'center',
			verticalPosition:'top'
		}
	}
];

export const appConfig:ApplicationConfig={
	providers:[
		provideRouter(
			routes,
			withPreloading(SelectivePreloadingStrategyService),
			withRouterConfig({canceledNavigationResolution:'computed'})
		),
		provideClientHydration(),
		provideServiceWorker(
			'ngsw-worker.js',
			{
				enabled:!isDevMode(),
				registrationStrategy:'registerWhenStable:30000'
			}
		),
		provideAnimations(),
		provideHttpClient(
			withFetch(),
			withJsonpSupport(),
			withInterceptors([authenticationInterceptor])
		),
		graphqlProvider,
		AppService,
		AuthenticationService,
		CookieService,
		importProvidersFrom(
			NgxWebstorageModule.forRoot({prefix:'app',caseSensitive:true})
		),
		...appDefaultOptions
	]
};
