<mat-toolbar color="primary" role="heading">
	<mat-toolbar-row>
		<button mat-icon-button type="button" (click)="drawerInput?.toggle()" aria-label="Toggle sidenav">
			<mat-icon>menu</mat-icon>
		</button>
		<a [routerLink]="['/']" rel="bookmark">
			<img ngSrc="./assets/icons/favicon.png" alt="home" [height]="100" [width]="100" priority>
		</a>
		<div class="spacer"></div>
		<div *ngIf="appService.user | async" class="app-pointer" matRipple>
			<div #loginMenuTrigger [matMenuTriggerFor]="menuLogin" tabindex="0" (keyup.space)="loginMenuTrigger.click()" class="menu-login-trigger">
				<mat-icon aria-label="Profile" class="app-24">person</mat-icon>
				&nbsp;
				<span>{{(appService.user | async)?.firstName}}</span>
			</div>
			<mat-menu #menuLogin="matMenu">
				<mat-nav-list>
					<a #logOut mat-list-item (click)="authenticationService.logout()" (keyup.space)="logOut._elementRef.nativeElement.click()" dir="ltr" tabindex="0">
						Log out &nbsp;
						<mat-icon>logout</mat-icon>
					</a>
				</mat-nav-list>
			</mat-menu>
		</div>
	</mat-toolbar-row>
</mat-toolbar>

