<mat-card>
	<div>
		<div>
			<p>Welcome, enter your credentials</p>
		</div>
		<div class="form-container">
			<form [formGroup]="form" (ngSubmit)="login()">
				<mat-form-field floatLabel="auto">
					<input matInput type="email" placeholder="Email" name="email" formControlName="email" benditaAdminInputEmptyToNull required>
				</mat-form-field>
				<mat-form-field floatLabel="auto">
					<input matInput type="password" placeholder="Contraseña" name="password" formControlName="password" benditaAdminInputEmptyToNull required>
				</mat-form-field>
				<div>
					<bendita-admin-form-message [serverErrorInput]="serverError" [serverResponseInput]="serverResponse"></bendita-admin-form-message>
				</div>
				<div>
					<button type="submit" mat-button class="app-primary">Log in</button>
				</div>
			</form>
		</div>
	</div>
</mat-card>
